import { faClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';

export const duplicateReport = (context: ActionContext) =>
  createAction(
    {
      id: 'duplicate-report',
      label: i18n.t('duplicate'),
      icon: <Icon icon={faClone} />,
      scopes: UserPermission.REPORT_CREATE,
      perform: ({ id }: { id: number }) => {
        context.modalHistory.push({
          hash: modalRoutes.DUPLICATE_REPORT,
          search: `?id=${id}`,
        });
      },
    },
    context,
  );
