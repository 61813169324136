import {
  ComponentType,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Provider } from 'components/insights/context';
import { ApolloProvider, useQuery } from '@apollo/client';
import { ALL_PAGES } from 'graphql/insights/queries/pagesQuery';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import { BrowserStorage } from 'utils/browserStorage';
import {
  allPagesQuery,
  allPagesQuery_pages_edges,
} from 'graphql/insights/queries/__generated__/allPagesQuery';
import { ALL_METRICS_PROPERTY } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { Page } from 'app/modules/insights/types/Page';
import {
  defaultEndDate,
  defaultStartDate,
  FilterState,
  useFilterState,
} from 'app/modules/insights/hooks/useFilterState';
import {
  ActiveState,
  useActiveState,
} from 'app/modules/insights/hooks/useActiveState';
import { UserPageConfiguration } from 'app/modules/insights/types/UserPageConfiguration';
import usePrevious from 'utils/hooks/usePrevious';
import DomUtils from 'app/utils/dom';
import { DateRangeOnChangeParams } from 'types/Date';
import { OrderDirection } from 'app/types';
import { useUrlSideEffectForUserPilot } from 'app/modules/insights/hooks/useUrlSideEffectForUserPilot';
import { useHistoryQueryParams } from 'app/hooks/useHistoryQueryParams';
import DateUtils from 'app/utils/date';
import { runIfFn } from 'app/utils/run-if-fn';
import { useApp } from 'modules/app/appSelector';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';

export type InsightsState = {
  arePagesLoading: boolean;
  changeSelectedProfiles: (id: string) => void;
  changeOrderDirection(dir: OrderDirection): void;
  filterState: FilterState;
  activeState: ActiveState;
  changeDateFilter(
    dates: DateRangeOnChangeParams,
    setPeriodToCompare?: boolean,
  ): void;
  changePreset: (preset?: string) => void;
  changeSelectedTypes: (type: number[] | number) => void;
  clearSelectedTypes: () => void;
  changeSelectedMetric: (metric: string) => void;
  pages: Page[];
  clearComparedDateFilter: () => void;
  postTypesCount?: Record<string, number>;
  setPostTypesCount: (
    postTypesCount: Record<string, number> | undefined,
  ) => void;
  userPageConfiguration: UserPageConfiguration;
  setUserPageConfiguration: (data: UserPageConfiguration) => void;
};

type Props = {
  children: ((value: InsightsState) => ReactNode) | ReactNode;
};

const getMetricOrderData = (page: allPagesQuery_pages_edges) => {
  return (
    page.node.userPageConfiguration?.map((pageConfiguration) => ({
      metricKey: pageConfiguration.metricKey!,
      expanded: pageConfiguration.expanded!,
    })) ?? []
  );
};

const Container: FC<Props> = ({ children }) => {
  const [filterState, setFilterState] = useFilterState();

  const [postTypesCount, setPostTypesCount] = useState<
    Record<string, number> | undefined
  >(undefined);
  const [userPageConfiguration, setUserPageConfiguration] =
    useState<UserPageConfiguration>({});

  const { data, loading } = useQuery<allPagesQuery>(ALL_PAGES, {
    onCompleted: onCompleted,
  });
  const { deleteQueryParameter, setQueryParameter } = useHistoryQueryParams();

  const pages = useMemo(
    () => data?.pages?.edges.map((page) => page.node) ?? [],
    [data?.pages?.edges],
  );
  const prevPages = usePrevious(pages);

  const changeSelectedProfiles = useCallback(
    (id: string) => {
      setQueryParameter('pages', [id]);

      setFilterState({
        selectedProfiles: [id],
        selectedMetric: ALL_METRICS_PROPERTY.key,
      });
    },
    [setFilterState, setQueryParameter],
  );

  useEffect(() => {
    const canPreselectProfile =
      (!prevPages || prevPages?.length === 0) &&
      pages.length > 0 &&
      filterState.selectedProfiles.length === 0;

    if (canPreselectProfile) {
      changeSelectedProfiles(pages[0]._id);
    }
  }, [pages, prevPages, filterState.selectedProfiles, changeSelectedProfiles]);

  const activeState = useActiveState({ filterState, pages });

  useUrlSideEffectForUserPilot({ activeState });

  useEffect(() => {
    BrowserStorage.set(
      BrowserStorage.keys.Insights.InsightsFilters,
      JSON.stringify(filterState),
    );
  }, [filterState]);

  function onCompleted(data: allPagesQuery) {
    addUserPageConfiguration(data);
  }

  function addUserPageConfiguration(data: allPagesQuery) {
    const newUserPageConfiguration: UserPageConfiguration = {};

    data.pages?.edges.forEach((page) => {
      newUserPageConfiguration[page.node.id] = getMetricOrderData(page);
    });

    setUserPageConfiguration(newUserPageConfiguration);
  }

  function changeOrderDirection(orderDirection: OrderDirection) {
    setFilterState({ orderDirection });

    if (orderDirection === OrderDirection.Asc) {
      deleteQueryParameter('order');
    } else {
      setQueryParameter('order', orderDirection);
    }
  }

  function changePreset(preset?: string) {
    setFilterState({ datePreset: preset });

    if (preset) {
      setQueryParameter('preselect', preset);
    } else {
      deleteQueryParameter('preselect');
    }
  }

  function changeDateFilter(
    dates: DateRangeOnChangeParams,
    setPeriodToCompare?: boolean,
  ) {
    const startDate = dates.startDate ?? defaultStartDate;
    const endDate = dates.endDate ?? defaultEndDate;

    if (setPeriodToCompare) {
      setFilterState({
        comparedStartDate: startDate,
        comparedEndDate: endDate,
      });
      setQueryParameter('comparedStartDate', DateUtils.toDateString(startDate));
      setQueryParameter('comparedEndDate', DateUtils.toDateString(endDate));
    } else {
      setFilterState({
        startDate,
        endDate,
        comparedStartDate: null,
        comparedEndDate: null,
      });
      deleteQueryParameter('comparedStartDate');
      deleteQueryParameter('comparedEndDate');
      setQueryParameter('startDate', DateUtils.toDateString(startDate));
      setQueryParameter('endDate', DateUtils.toDateString(endDate));
    }
  }

  const changeSelectedTypes = useCallback(
    (types: number[] | number) => {
      setFilterState((prevFilters) => {
        const arrayTypes = Array.isArray(types) ? types : [types];

        const newSelectedTypes = arrayTypes.reduce(
          (selectedTypes, currentType) => {
            return togglePrimitiveItemInArray<number>(
              currentType,
              selectedTypes,
            );
          },
          prevFilters.selectedPostTypes,
        );

        if (newSelectedTypes.length) {
          setQueryParameter('types', newSelectedTypes);
        } else {
          deleteQueryParameter('types');
        }

        return { selectedPostTypes: newSelectedTypes };
      });
    },
    [setFilterState, setQueryParameter, deleteQueryParameter],
  );

  const clearSelectedTypes = () => {
    DomUtils.scrollTo('insights-main-route', 0);
    deleteQueryParameter('types');
    setFilterState({
      selectedPostTypes: [],
    });
  };

  const changeSelectedMetric = (metric: string) => {
    setQueryParameter('metric', metric);

    setFilterState({ selectedMetric: metric });
  };

  const clearComparedDateFilter = () => {
    setFilterState({ comparedStartDate: null, comparedEndDate: null });
  };

  const insightsState = {
    arePagesLoading: loading,
    changeSelectedProfiles,
    changeOrderDirection,
    changeDateFilter,
    filterState,
    activeState,
    changeSelectedTypes,
    changePreset,
    clearSelectedTypes,
    changeSelectedMetric,
    pages,
    clearComparedDateFilter,
    postTypesCount,
    setPostTypesCount,
    userPageConfiguration,
    setUserPageConfiguration,
  };

  return (
    <Provider value={insightsState}>
      {runIfFn(children, insightsState)}
    </Provider>
  );
};

export const WithAnalytics = (Component: ComponentType) => {
  const hocComponent = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { authToken } = useApp();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [client] = useState(() =>
      createInsightsApolloClient(authToken as string),
    );

    return (
      <ApolloProvider client={client}>
        <Container>
          <Component />
        </Container>
      </ApolloProvider>
    );
  };

  return hocComponent;
};

export default Container;
