import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { UserPermission } from 'constants/userPermission';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';

export const viewReport = (context: ActionContext) =>
  createAction(
    {
      id: 'view-report',
      label: i18n.t('view'),
      icon: <Icon icon={faEye} />,
      scopes: UserPermission.REPORT_EDIT,
      perform: ({ id }: { id: number }) => {
        // TODO: view report
      },
    },
    context,
  );
