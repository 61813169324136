import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';

export const deleteReport = (context: ActionContext) =>
  createAction(
    {
      id: 'delete-report',
      label: i18n.t('delete'),
      icon: <Icon icon={faTrash} />,
      scopes: UserPermission.REPORT_DELETE,
      perform: ({ id }: { id: number }) => {
        context.modalHistory.push({
          hash: modalRoutes.DELETE_REPORT,
          search: `?id=${id}`,
        });
      },
    },
    context,
  );
