import MetricHeader from 'app/modules/insights/modules/postsPerformance/components/MetricHeader';
import { usePostsPerformanceState } from 'app/modules/insights/modules/postsPerformance/hooks/usePostsPerformanceState';
import { getMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { Page } from 'app/modules/insights/types/Page';
import ArrayUtils from 'app/utils/array';
import { FC, useCallback } from 'react';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { usePostsByMetricQuery } from '../hooks/usePostsByMetricQuery';
import PostsUnavailable from './bestPostsForMetrics/PostsUnavailable';
import { PostCollapsedLayoutVariant } from 'app/modules/insights/modules/postsPerformance/components/postCollapsed/PostCollapsedLayout';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Dayjs } from 'dayjs';
import SimpleGrid from 'app/components/SimpleGrid';
import StandardPostPreview from 'app/modules/insights/components/StandardPostPreview';
import { getMedalRankFromIndex } from 'app/modules/insights/utils/getMedalRankFromIndex';
import InsightsConfig from 'app/config/insights';
import PostPreviewPlaceholder from './bestPostsForMetrics/PostPreviewPlaceholder';
import StandardPostPreviewSkeleton from 'app/modules/insights/components/StandardPostPreviewSkeleton';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
  onPostsCountChanged?: (count: Record<string, number> | undefined) => void;
  paginateBy?: number;
  postCollapsedLayoutVariant?: PostCollapsedLayoutVariant;
};

const PostsByMetricSimpleList: FC<Props> = (props) => {
  const { setMetricHeader } = usePostsPerformanceState();
  const { page, metric } = props;
  const { posts, loading, error } = usePostsByMetricQuery(props);

  useEffectOnce(() => {
    return () => setMetricHeader(undefined);
  });

  const handleOnVisibleChange = useCallback(
    (
      visibility: {
        isVisible: boolean;
        wasEverVisible: boolean;
      },
      metric: MetricProperty,
    ) => {
      if (visibility.wasEverVisible) {
        setMetricHeader(!visibility.isVisible ? metric : undefined);
      }
    },
    [setMetricHeader],
  );

  return (
    <>
      <MetricHeader
        metric={getMetricsForPageType(page?._type, true)[metric.key]}
        onVisibleChange={handleOnVisibleChange}
        className="tw-mb-6"
      />
      {posts && (
        <>
          {posts.length > 0 && (
            <SimpleGrid columns={3} gap={16} className="tw-mb-4">
              {posts.map((post, index) => (
                <StandardPostPreview
                  key={`post-${index}`}
                  post={post}
                  medalRank={getMedalRankFromIndex(index)}
                  metric={{ ...metric, value: '0' }}
                  classNames={{
                    root: 'tw-h-[432px] tw-w-full',
                  }}
                />
              ))}
              {ArrayUtils.generate(
                InsightsConfig.MAX_POSTS_PER_METRIC - posts.length,
              ).map((value) => (
                <PostPreviewPlaceholder
                  key={`post-preview-placeholder-${value}`}
                />
              ))}
            </SimpleGrid>
          )}
        </>
      )}
      {error && (
        <PostsUnavailable variant="danger">
          {error.message ??
            `We were unable to get posts available for this metric and selected
          time frame in your profile.`}
        </PostsUnavailable>
      )}
      {loading && (
        <SimpleGrid columns={3} gap={16} className="tw-mb-4">
          {ArrayUtils.generate(3).map((item) => (
            <StandardPostPreviewSkeleton
              key={`skeleton-${item}`}
              className="tw-h-[432px] tw-w-full"
            />
          ))}
        </SimpleGrid>
      )}
    </>
  );
};

export default PostsByMetricSimpleList;
