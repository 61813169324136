import React from 'react';
import {
  FB_METRICS_PROPERTIES,
  getMetricsProperty,
  IG_METRICS_PROPERTIES,
} from 'app/modules/insights/modules/pagePerformance/utils/metricProperty';
import PageMetricCard from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCard';
import { Page } from 'app/modules/insights/types/Page';
import SimpleGrid from 'app/components/SimpleGrid';
import { SectionView } from 'types/Report';

type Props = {
  page: Page;
  selectedMetrics?: string[];
  view: SectionView;
};

const PagePerformanceSectionExport = ({
  page,
  selectedMetrics,
  view,
}: Props) => {
  const getDefaultTab = (metricKey: string) => {
    if (metricKey === IG_METRICS_PROPERTIES.followerDemographics.key) {
      return 'follower_demographics_country';
    }

    if (metricKey === IG_METRICS_PROPERTIES.reachedAudience.key) {
      return 'reached_audience_country';
    }

    return undefined;
  };

  const cols = view === 'expanded' ? 1 : 1;

  return (
    <div className="tw-w-full">
      <div style={{ breakInside: 'avoid' }}>
        <div className="tw-block">
          <SimpleGrid
            columns={cols}
            gap={0}
            style={{ rowGap: 12, columnGap: 12 }}
          >
            {selectedMetrics?.map((metricKey, index) => {
              const cardMetric = getMetricsProperty(page._type, metricKey);

              if (!cardMetric) return null;

              return (
                <div style={{ breakInside: 'avoid' }} key={metricKey}>
                  <PageMetricCard
                    showTabs={false}
                    key={metricKey}
                    page={page}
                    metric={cardMetric}
                    expanded={view === 'expanded'}
                    onToggleExpandClick={() => null}
                    chartHeight={
                      metricKey === FB_METRICS_PROPERTIES.audience.key
                        ? 287
                        : 208
                    }
                    defaultTab={getDefaultTab(metricKey)}
                  />
                </div>
              );
            })}
          </SimpleGrid>
        </div>
      </div>
    </div>
  );
};

export default PagePerformanceSectionExport;
