import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { UserPermission } from 'constants/userPermission';
import { faRefresh } from '@fortawesome/pro-regular-svg-icons/faRefresh';

export const regenerateReport = (context: ActionContext) =>
  createAction(
    {
      id: 'regenerate-report',
      label: i18n.t('regenerate'),
      icon: <Icon icon={faRefresh} />,
      scopes: UserPermission.REPORT_EXPORT,
      perform: ({ id }: { id: number }) => {
        // TODO: edit
      },
    },
    context,
  );
