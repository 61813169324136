import React, { FC, useState } from 'react';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';
import { ApolloProvider } from '@apollo/client';
import { StringParam, useQueryParams } from 'use-query-params';
import ReportFailedToBuildMessage from 'app/modules/public/modules/pdfReports/components/ReportFailedToBuildMessage';
import ReportApi from 'api/report';
import { queryKey } from 'constants/queryKey';
import { useQuery } from 'react-query';
import PdfExportCoverPage from '../../components/PdfExportCoverPage';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Container from 'components/insights/Container';
import ReportHeader from '../insightsPostsPerformance/components/reportPostsPerformance/ReportHeader';
import PageStatistics from 'app/modules/insights/modules/postsPerformance/components/PageStatistics';
import { getMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import Skeleton from 'react-loading-skeleton';
import PostsByMetricSimpleList from 'app/modules/insights/modules/postsPerformance/components/PostsByMetricSimpleList';
import { ReportNoteCard } from './components/ReportNoteCard';
import PagePerformanceSectionExport from './components/PagePerformanceSectionExport';
import { ReportUtils } from 'utils/report';

type Props = {};

const CustomReportRoute: FC<Props> = (props) => {
  const { id } = useParams();
  let [params] = useQueryParams({
    token: StringParam,
  });
  const token =
    params.token ??
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoxLCJ3c2lkIjoiMWE2NDdhM2QxM2NmNDY0ZTlkY2QyYTY1ZWU5MTFjOWM2NzNhZjVmNDg5NjE3Ni4zNDQzODIyMCJ9LCJhY2NvdW50Ijp7ImlkIjoyfSwiYXBwIjoiNmQ4OTZjNGZlNzVkMGU0MWEwOGYwMmI2MDgwNGU0MjgiLCJpYXQiOjE3MzE5MTczMDAsImV4cCI6MTczNDUwOTMwMH0.plwavgfVuqTm2Z2nAwpKuWZjOQoVY5lIHp6WMxl0C1I';

  const [client] = useState(() => createInsightsApolloClient(token ?? ''));

  const { isLoading, data } = useQuery(
    queryKey.reportDetail(Number(id)),
    () => {
      return ReportApi.detail(Number(id), token);
    },
  );

  if (isLoading) {
    return <Skeleton width="100%" height={'200px'} />;
  }

  if (data && token) {
    return (
      <ApolloProvider client={client}>
        <Container>
          {({ pages }) => (
            <>
              <div
                style={{ breakInside: 'avoid' }}
                className="tw-min-h-[1137px]"
              >
                <PdfExportCoverPage
                  title={data.name}
                  startDate={dayjs(data.date_from)}
                  endDate={dayjs(data.date_to)}
                />
              </div>
              {data.profiles?.map((profile) => {
                const page = pages.find(
                  (p) =>
                    ReportUtils.getIdFromComposedId(p.id) ===
                    profile.profile_id,
                );
                if (!page) return null;
                return profile.sections.map((section) => {
                  const metrics = Object.values(
                    getMetricsForPageType(page._type),
                  ).filter((metric) =>
                    (section.metrics ?? []).includes(metric.key),
                  );
                  return {
                    summary_cards: (
                      <div style={{ breakInside: 'avoid' }} key={section.id}>
                        <ReportHeader
                          name={data.name}
                          page={page}
                          startDate={dayjs(data.date_from)}
                          endDate={dayjs(data.date_to)}
                        />
                        <PageStatistics
                          page={page}
                          startDate={dayjs(data.date_from)}
                          endDate={dayjs(data.date_to)}
                          isExpandingDisabled
                          columns={2}
                        />
                        <ReportNoteCard note={section.note} />
                      </div>
                    ),
                    page_performance: (
                      <div style={{ breakInside: 'avoid' }} key={section.id}>
                        <ReportHeader
                          name={data.name}
                          page={page}
                          startDate={dayjs(data.date_from)}
                          endDate={dayjs(data.date_to)}
                        />
                        <div className="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                          <span className="tw-text-xl tw-font-bold">
                            Page performance
                          </span>
                        </div>
                        <PagePerformanceSectionExport
                          page={page}
                          selectedMetrics={section.metrics}
                          view={section.view}
                        />
                        <ReportNoteCard note={section.note} />
                      </div>
                    ),
                    posts_performance: (
                      <div style={{ breakInside: 'avoid' }} key={section.id}>
                        <ReportHeader
                          name={data.name}
                          page={page}
                          startDate={dayjs(data.date_from)}
                          endDate={dayjs(data.date_to)}
                        />
                        <div>
                          {metrics.map((metric) => (
                            <PostsByMetricSimpleList
                              key={metric.key}
                              page={page}
                              metric={metric}
                              types={[]}
                              startDate={dayjs(data.date_from)}
                              endDate={dayjs(data.date_to)}
                              paginateBy={
                                { top_5: 5, all: 20, top_10: 10, top_15: 15 }[
                                  section.posts_to_include
                                ]
                              }
                            />
                          ))}
                        </div>
                        <ReportNoteCard note={section.note} />
                      </div>
                    ),
                  }[section.type];
                });
              })}
            </>
          )}
        </Container>
      </ApolloProvider>
    );
  }

  return <ReportFailedToBuildMessage />;
};

export default CustomReportRoute;
