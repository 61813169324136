import { get, httpDelete, post, put } from 'api/client';
import env from 'config';
import { Report } from 'types/Report';

const ReportApi = {
  getAll(params: {
    page?: number,
    search?: string
    sort_by?: string,
    sort_dir?: string
  }): Promise<{data: Report[], meta: {
    total: number,
    current_page: number,
    per_page: number,
    last_page: number
  }}> {
    return get(`/api/reports`, {
      baseURL: env.REPORTS_API_ENDPOINT,
      params,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    })
  },
  detail(reportId: number, jwtToken?: string): Promise<Report> {
    return get(`/api/reports/${reportId}`, {
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
        ...(jwtToken && {
          Authorization: `Bearer ${jwtToken}`,
        })
      }
    });
  },
  create(report: {}): Promise<Report> {
    return post(`/api/reports`, report, {
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    });
  },
  delete(reportId: number): Promise<Report> {
    return httpDelete(`/api/reports/${reportId}`, {
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    });
  },
  edit(report: {id:number}): Promise<Report> {
    return put(`/api/reports/${report.id}`, report, {
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    });
  },
  duplicate({
    id,
    name
  }: {id: number, name: string}): Promise<Report> {
    return post(`/api/reports/${id}/duplicate`, {
      name
    },{
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    });
  },
  createExport(reportId: number): Promise<Report> {
    return post(`/api/reports/${reportId}/exports/csv`, {
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    });
  },
  getExport(reportId: number): Promise<Report> {
    return get(`/api/reports/${reportId}/exports/csv`, {
      baseURL: env.REPORTS_API_ENDPOINT,
      headers: {
        'ngrok-skip-browser-warning': 'true',
      }
    });
  },
};

export default ReportApi;
