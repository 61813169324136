import React from 'react';

export type ReportNoteCardProps = {
  note?: string;
};
export const ReportNoteCard = ({ note }: ReportNoteCardProps) => {
  if (!note) return null;
  return (
    <div className="tw-w-full tw-rounded-md tw-bg-grayscale-5 tw-p-6">
      <span className="tw-font-semibold">Notes:&nbsp;</span>
      {note}
    </div>
  );
};
