import PageTypeUtils from "app/utils/pageType";
import { getMetricsForPageType as getPageMetricsForPageType } from 'app/modules/insights/modules/pagePerformance/utils/metricProperty';
import { getMetricsForPageType as getPostsMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import i18n from "app/utils/i18n";
import { SectionType } from "types/Report";

export const ReportUtils = {
	getIdFromComposedId: (id: string) => Number(atob(id).split('-')[1]),
	getMetricOptions: ({pageType, type}: {pageType: string, type: SectionType}) => {
		  const pageTypeNumber = PageTypeUtils.getNumberFromName(pageType);
    const metrics =
      type === 'page_performance'
        ? getPageMetricsForPageType(pageTypeNumber)
        : getPostsMetricsForPageType(pageTypeNumber);
    return Object.values(metrics).map((metric) => ({
      value: metric.key,
      label: i18n.t(metric.title),
    }));
	}
}